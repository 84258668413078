import React, { useEffect, useState } from "react";
import "./App.scss";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
//@ts-ignore
import transakSDK from "@transak/transak-sdk";
import SendModal from "./components/Send";
import * as Cookies from "js-cookie";

const walletLink = new WalletLink({
  appName: "Centum Dapp",
  darkMode: false,
});

function App() {
  const [provider, setProvider] = useState<any>();
  const [account, setAccount] = useState<string>();
  const [balance, setBalance] = useState<string>();
  const [showSend, setShowSend] = useState(false);

  useEffect(() => {
    loadBlockChainData();
    // eslint-disable-next-line
  }, [provider]);

  const connect = async () => {
    const provider = new WalletConnectProvider({
      infuraId: "c9c98a38625d4da78ced9ccd8dc701ef",
    });
    try {
      await provider.enable();
      setProvider(provider);
      Cookies.set("eth_provider", "walletconnect");
    } catch (e) {
      console.error(e);
    }
  };

  const connectMetamask = async () => {
    const ethereum = window.ethereum;
    if (ethereum) {
      // @ts-ignore
      await ethereum.enable();
      setProvider(ethereum);
      Cookies.set("eth_provider", "metamask");
    } else {
      alert("Please install MetaMask!");
    }
  };

  const connectCoinbase = async () => {
    const provider = walletLink.makeWeb3Provider(
      "https://mainnet.infura.io/v3/c9c98a38625d4da78ced9ccd8dc701ef",
      1
    );
    try {
      await provider.enable();
      setProvider(provider);
      Cookies.set("eth_provider", "coinbase");
    } catch (e) {
      console.error(e);
    }
  };

  const disconnect = async () => {
    const type = Cookies.get("eth_provider");
    if (type === "walletconnect" || type === "coinbase") {
      await provider.disconnect();
    }
    Cookies.remove("eth_provider");
    setProvider(undefined);
    setAccount(undefined);
  };

  const loadBlockChainData = async () => {
    if (!provider) return;
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    setAccount(accounts[0]);
    const balance = await web3.eth.getBalance(accounts[0]);
    setBalance(balance);
  };

  const handleBuyFromTransak = () => {
    let transak = new transakSDK({
      apiKey: "13938e6f-94fe-48da-86b3-6cc7649bb02a", // Your API Key
      environment: "STAGING", // STAGING/PRODUCTION
      defaultCryptoCurrency: "ETH",
      walletAddress: account, // Your customer's wallet address
      themeColor: "5e72e4", // App theme color
      fiatCurrency: "", // INR/GBP
      email: "", // Your customer's email address
      redirectURL: "",
      hostURL: window.location.origin,
      widgetHeight: "550px",
      widgetWidth: "450px",
    });

    transak.init();

    // To get all the events
    transak.on(transak.ALL_EVENTS, (data: any) => {
      console.log(data);
    });

    // This will trigger when the user marks payment is made.
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData: any) => {
      console.log(orderData);
      transak.close();
    });
  };

  const sendTokens = (to: string, amount: string) => {
    const web3 = new Web3(provider);
    const value = web3.utils.toWei(amount, "ether");
    const payload = {
      to,
      from: account,
      value,
    };
    return web3.eth.sendTransaction(payload, (error, transactionHash) => {
      if (error) return new Error("Issue sending Transaction");
      console.log(transactionHash);
      return transactionHash;
    });
  };

  return (
    <section className="app">
      <div className="header bg-gradient-primary text-center pb-5 pt-5 pt-md-5">
        <h1 className="text-uppercase text-light">Centum Dapp</h1>
      </div>
      <div className="container text-center mt-5">
        {!provider ? (
          <React.Fragment>
            <button
              className="btn btn-primary btn-lg"
              onClick={connectMetamask}
            >
              Connect with Metamask
            </button>
            <hr />
            <button className="btn btn-primary btn-lg" onClick={connect}>
              Connect with WalletConnect
            </button>
            <hr />
            <button className="btn btn-primary btn-lg" onClick={connectCoinbase}>
              Connect with Coinbase
            </button>
          </React.Fragment>
        ) : (
          <div>
            <table className="table table-borderless mx-auto">
              <tbody>
                <tr>
                  <th scope="row">Your Account: </th>
                  <td style={{ wordBreak: "break-all" }}>{account}</td>
                </tr>
                <tr>
                  <th scope="row">Balance: </th>
                  <td>{balance} ETH</td>
                </tr>
              </tbody>
            </table>
            <div className="my-4 mx-auto">
              <button
                className="btn btn-outline-primary me-3"
                onClick={handleBuyFromTransak}
              >
                Buy
              </button>
              <button
                className="btn btn-outline-primary me-3"
                onClick={() => setShowSend(true)}
              >
                Send
              </button>
            </div>
            <button className="btn btn-danger btn-lg" onClick={disconnect}>
              Disconnect
            </button>
          </div>
        )}
      </div>
      <SendModal
        show={showSend}
        onClose={() => setShowSend(false)}
        onSend={sendTokens}
      />
    </section>
  );
}

export default App;
